import React from 'react';
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Footer from "./components/Footer";
import Clients from "./components/Clients";
import Contacts from './components/Contacts';
import Services from './components/Services';
import Onfocus from './components/Onfocus';
import Testimonials from './components/Testimonials';
// import Faq from './components/Faq';
import Cta  from './components/Cta';
function App() {
  
  return (  

<main>

    <Header/>
    <Hero/>
    <About/>
    <Cta/>
    <Onfocus/>
    <Services/>
    <Clients/>
    
    <Testimonials/>
    {/* <Faq/> */}
    <Contacts/>
    <Footer/>
</main>

);}
export default App;
