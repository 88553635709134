import React from 'react'

function Cta() {
  return (<>   
  <section id="cta" className="cta">
      <div className="container" data-aos="zoom-out">
        <div className="section-header">
          <h2>What we Do</h2>
          <p> We Help Companies and Brands to get Digital Identities, that will help them to build their Image.
          </p>
        </div>
        <div className="row g-5">

          <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
            <h3>Share your Thought <em>with US</em> we will make them true</h3>
            <p> We deliver On-Demand Web and Mobile Applications, Graphics Designs, Digital Marketing and Consultuncy services to Start-Ups, Organizations
              and firms.</p>
            <a className="cta-btn align-self-start" href="index.html#contact">Call To Action</a>
          </div>

          <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
            <div className="img">
              <img src="assets/img/cta.jpg" alt="" className="img-fluid"></img>
            </div>
          </div>

        </div>

      </div>
    </section>
 
</>);}


export default Cta;