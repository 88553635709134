import React from 'react'

function Clients() {
  return (<>    

<section id="clients" className="clients">
      <div className="container" data-aos="zoom-out">

        <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
            <div className="swiper-slide"><img src="assets/img/clients/nodejs.svg" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/reactjs.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/vuejs.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/AWS.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/larawel.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/Android.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/flutter.png" className="img-fluid" alt=""></img></div>
            <div className="swiper-slide"><img src="assets/img/clients/wordpress.png" className="img-fluid" alt=""></img></div>
          </div>
        </div>

      </div>
    </section>
</>);}

export default Clients;