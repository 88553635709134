import React from 'react'

function Footer() {
  return (<>    

<footer id="footer" className="footer">

<div className="footer-content">
  <div className="container">
    <div className="row">

      <div className="col-lg-3 col-md-6">
        <div className="footer-info">
          <h3>UniRK</h3>
          <p>
            304, Pollaris Mall, Cenal Road, <br></br>
            Surat, Gujarat, IN. 395006<br></br>
            <strong>Phone:</strong> +91 94285 05823<br></br>
            <strong>Email:</strong> team@devbastion.in<br></br>
          </p>
        </div>
      </div>

      <div className="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><i className="bi bi-chevron-right"></i> <a href="">Home</a></li>
          <li><i className="bi bi-chevron-right"></i> <a href="index.html#about">About us</a></li>
          <li><i className="bi bi-chevron-right"></i> <a href="index.html#features">Services</a></li>
          {/* <li><i className="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li> */}
          {/* <li><i className="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li> */}
        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li><i className="bi bi-chevron-right"></i> Web Design</li>
          <li><i className="bi bi-chevron-right"></i> Web Development</li>
          <li><i className="bi bi-chevron-right"></i> Product Management</li>
          <li><i className="bi bi-chevron-right"></i> Marketing</li>
          <li><i className="bi bi-chevron-right"></i> Graphic Design</li>
        </ul>
      </div>

             </div>
  </div>
</div>

<div className="footer-legal text-center">
  <div
    className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

    <div className="d-flex flex-column align-items-center align-items-lg-start">
      <div className="copyright">
        &copy; 2021 <strong><span>UniRK</span></strong>. All Rights Reserved
      </div>
      
    </div>

 
  </div>
</div>

</footer>

<a href="#" className="scroll-top d-flex align-items-center justify-content-center">
<i className="bi bi-arrow-up-short"></i></a>

<div id="preloader"></div>
</>);}
export default Footer;