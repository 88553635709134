import React from 'react'

function About() {
  return (<>    


<section id="about" className="about">
<div className="container" data-aos="fade-up">

  <div className="section-header">
    <h2>Who Are We </h2>
    {/* <p>
      UniRK is software development service provider who will understand your business need and create
      efficient and effective solution.
    </p> */}
  </div>

  <div className="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">

    <div className="col-lg-5">
      <div className="about-img">
        <img src="assets/img/about.jpg" className="img-fluid" alt=""></img>
      </div>
    </div>

    <div className="col-lg-7">
      <h5 className="pt-0 "> UniRK is Web Development service provider who will understand your business need and create
      efficient and effective solution.
      </h5>

      <ul className="nav nav-pills mb-3">
        <li><a className="nav-link active" data-bs-toggle="pill" href="#tab1">History</a></li>
        <li><a className="nav-link" data-bs-toggle="pill" href="#tab2">Vision</a></li>
        <li><a className="nav-link" data-bs-toggle="pill" href="#tab3">Core Values</a></li>
      </ul>
      <div className="tab-content">

        <div className="tab-pane fade show active" id="tab1">

          <p className="fst-italic">We started as web-devlopment and mobile-App development Start-Up. With dedicated team and leaders UniRK got success.We started working On
          Digital Marketing and Branding, well that helpd us to grow.</p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Idea of starting company </h4>
          </div>
          <p > We wanted to give power of digital to those who want to grow their business exponentialy. We started with four members and only providing 
            web and mobile app development products.
          </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Growth with New Technologies</h4>
          </div>
          <p >We Provided Web Development services with HTML, CSS and JavaScript, Now we are using NodeJS, ReactJS, PHP for Web-Development and React Native and Flutter for Mobile App development.</p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Our Potential</h4>
          </div>
          <p >UniRK  helps enterprise software companies in their technical and digital transformation.
            we can accelerate the company’s potential growth.</p>

        </div>

        <div className="tab-pane fade show" id="tab2">

          <p className="fst-italic"> Building Customer Trust is out moto,
            for that We make sure nothing goes out with-out QA. We belive everyone should know and use power of digitalization, we 
            want to help people to achive this facilities. </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Reliable and Secure Apps</h4>
          </div>
          <p >      We have experienced Web and Mobile Developers who will conver your Idea into your application. which is easy to use and 
               </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Design and Illustart Art</h4>
          </div>
          <p > We belive in Simplicity Yet Innovation, Our Team of Designers create Graphics and Content
            that will help you grow your image. Marketing team make sure your Brand values.
            </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Simplicity Yet Impactful</h4>
          </div>
          <p > As a local Brand we belive everyone deserve smart Applications and for that Simplicity is more important, till we belive in remarkable Applications, 
              we create simple and easy to use applications.
          </p>

        </div>

        <div className="tab-pane fade show" id="tab3">

          <p className="fst-italic">Creativity is the core value at UniRK. We emphasize creativity for all 
          employees and encourage them to utilize time, space, and tools to bring their creative ideas to life and develop solutions. </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Quality</h4>
          </div>
          <p >Quality is something that we live by. We deliver solutions that ensure 
            customer satisfaction, profitability, and the future of our employees and our growth.</p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Learning</h4>
          </div>
          <p >Knowledge is valued over reinvention. We know that learning changes minds and lives. We aim to grow by embracing, encouraging as well as sharing the learnings.  </p>

          <div className="d-flex align-items-center mt-4">
            <i className="bi bi-check2"></i>
            <h4>Adaptability</h4>
          </div>
          <p >Turning setbacks into comebacks and adapting to every situation is what we do and believe. 
            We are adaptable to technology trends, clients’ requirements, and other factors equally.</p>

        </div>

      </div>

    </div>

  </div>

</div>
</section>
</>);}
export default About;
