import React from 'react'

function Onfocus() {
  return (<>    

<section id="onfocus" className="onfocus">
      <div className="container-fluid p-0" data-aos="fade-up">

        <div className="section-header">
          <h2>Our Belife</h2>
          <p>We believe in Collaboration  with Dev's and Clients for en excellent out-comes that truely desired.</p>
        </div>
        <div className="row g-0">
          <div className="col-lg-6 video-play position-relative">
          </div>
          <div className="col-lg-6">
            <div className="content d-flex flex-column justify-content-center h-100">
              <h3> Team & client Collaboration  </h3>
              <p className="fst-italic">
                To create content with maximum 
              </p>
              <ul>
                <li><i className="bi bi-check-circle"></i> Play to the Strengths of Individual Team Members.</li>
                <li><i className="bi bi-check-circle"></i> Create Trust Between Team Members.</li>
                <li><i className="bi bi-check-circle"></i> Have Transparent Team to Client Communication. </li>
                <li ><i className="bi bi-check-circle"></i> For Us, collaboration is not just a buzzword we use as a core value – it is something that is essential to how we do things. </li>

              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>
</>);}

export default Onfocus;