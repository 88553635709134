import React from 'react'

function Testimonials() {
  return (<>    



<section id="testimonials" className="testimonials">

      <div className="container" data-aos="fade-up">
        <div className="testimonials-slider swiper">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="assets/img/testimonials/testimonial01.jpg" className="testimonial-img" alt=""></img>
                <h3>Hemant Shah</h3>
                {/* <h4>Ceo &amp; Founder</h4> */}
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                    className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  UniRK has a team of telented nad creative developers, with understanding of project 
                  requirements. it was a great experience working with them.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="assets/img/testimonials/testimonial2.png" className="testimonial-img" alt=""></img>
                <h3>Sara Prakash</h3>
                {/* <h4>Designer</h4> */}
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                    className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  Designers at UniRK are very telented and great listners. then understood my requirements 
                  and designed correctly. Overall great company.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="assets/img/testimonials/testimonial03.png" className="testimonial-img" alt=""></img>
                <h3>Harish Pancholi</h3>
                {/* <h4>Store Owner</h4> */}
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                    className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  I worked with UniRK for one month and it was such a wonderful time, helped me with my E-commerse site development 
                  and hosting, it is working just fine.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="assets/img/testimonials/testimonial04.png" className="testimonial-img" alt=""></img>
                <h3>Mohit Varma</h3>
                {/* <h4>Freelancer</h4> */}
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                    className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  
					        	UniRK Solutions - one stop solution when it's about developing your business. The work deliver is amazing, satisfied and with utmost depth the work is being done. High qualified work and with best result the work is being resulted. The owner believes in professionalism. With best rates he provides the work at the perfection.					        
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src="assets/img/testimonials/testimonial05.png" className="testimonial-img" alt=""></img>
                <h3>John Cristion</h3>
                {/* <h4>Entrepreneur</h4> */}
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                    className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  
					        	"Working with UniRK  was great, best part is of their reasonable rates, pro-activeness & readiness for work. Best of luck, Keep it Up."					        
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>

          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  </>);}
export default Testimonials;
