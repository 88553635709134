import React from 'react'

function Hero() {
  return (<>    

<section id="home" className="hero-animated d-flex align-items-stretch">
    <div className="
                container
                d-sm-flex 
                d-lg-flex
                flex-sm-column 
                flex-lg-row-reverse
                justify-content-sm-center 
                justify-content-lg-center
                align-items-sm-center 
                align-items-lg-center 
                text-center 
                position-relative
                " data-aos="zoom-in">
      <img src="assets/img/Banners/banner-1.png" className="img-fluid animated" alt='banner'></img>
      <div className="d-lg-flex flex-sm-column align-items-start">
        <h2>Welcome to <span>UniRK</span></h2>
        <p  className="text-sm-center text-lg-start">
          We're Web Development and Mobile App Development Comapny.
          We Create Innovative and Reliable Apps for Start-Ups, Oranizations and small Businesses.  
        </p>
        <div className="d-flex justify-content-center">
          <a href="#about" className="btn-get-started scrollto"> Get Started </a>
 
        </div>
      </div>
    </div>
  </section>

  </>);}
export default Hero;
