import React from 'react'

function Header() {
  return (<>    

<header id="header" className="header fixed-top" data-scrollto-offset="0">
    <div className="container-fluid d-flex align-items-center justify-content-between">

      <a href="index.html" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
        <img src="unirk_logo.png" alt=""></img>
      </a>

      <nav id="navbar" className="navbar">
        <ul>

          <li><a className="nav-link scrollto" href="index.html#home">Home</a></li>
          <li><a className="nav-link scrollto" href="index.html#about">About</a></li>
          <li><a className="nav-link scrollto" href="index.html#cta"> WhatWeDo</a></li>
          <li><a className="nav-link scrollto" href="index.html#onfocus">Belife</a></li>

          <li><a className="nav-link scrollto" href="index.html#features">Services</a></li>
          <li><a className="nav-link scrollto" href="index.html#testimonials">Testimonials</a></li>
          {/* <li><a className="nav-link scrollto" href="index.html#faq">FAQ</a></li> */}
          <li><a className="nav-link scrollto" href="index.html#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle d-none"></i>
      </nav>

      <a className="btn-getstarted scrollto d-none d-lg-block" href="index.html#contact">Contact Us</a>

    </div>
  </header>
  </>);}
export default Header;
