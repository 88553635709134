import React from 'react'

function Services() {
  return (<>


    <section id="features" className="features">

      <div className="section-header">
        <h2>Our Services</h2>
        <p>We're ready to help you with thoughtful marketing strategies, imaginative web design that is User, All Device and SEO friendly, so you can present your business and products to the world with pride and confidence.</p>
      </div>
      <div className="container" data-aos="fade-up">

        <ul className="nav nav-tabs row gy-4 d-flex">

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1" href=''>
              <i className="bi bi-window color-cyan"></i>
              <h4 >WebSite Design</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2" href=''>
              <i className="bi bi-phone color-indigo"></i>
              <h4 >AppDevelopment</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3" href=''>
              <i className="bi bi-wordpress color-teal"></i>
              <h4 >Wordpress</h4>
            </a>
          </li>

          {/* <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
              <i className="bi bi-graph-up-arrow color-red"></i>
              <h4 >Digital Marketing</h4>
            </a>
          </li> */}

          {/* <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
              <i className="bi bi-cart3 color-blue"></i>
              <h4 >E-Commerce </h4>
            </a>
          </li> */}

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-6" href=''>
              <i className="bi bi-cloud-fog2-fill color-orange"></i>
              <h4 >Cloud Services</h4>
            </a>
          </li>

        </ul>

        <div className="tab-content">

          <div className="tab-pane active show" id="tab-1">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
                <h3>WebSite Development</h3>
                <p >
                  UniRK is leading Website design & development company in Surat. We can assist you in Website Design ,Website Development,
                  CMS Website Development (Content Management System),
                  E-Commerce Website Development , Cutom Web Development .
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> We build Responsive Website and WebApplications.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Security is at our core, we create secure Apis and Applications.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Our web developing team are experts in providing unique, custom PHP Development Services.
                  We have access to the latest innovation in PHP development which, along with our creative and coding expertise helps us deliver
                    the best results that’ll exceed your expectations.</li>
                </ul>
                {/* <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
            sunt in
            culpa qui officia deserunt mollit anim id est laborum
          </p> */}
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-1.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-2">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>App Development</h3>
                <p>
                  Have an idea and want to launch a mobile app? We can help! We are a leading Mobile Application Development
                  Company who can help you craft unique mobile apps,
                  that are aligned with the current market trends, on varied platforms including Android, iOS, iPad and iPhone.
                </p>
                {/* <p className="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua.
          </p> */}
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> As per your requirement, We build the most beautiful and user-friendly UI.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Develop an application by using the latest technology.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> We believe in giving quality work instead of quantity work.
                  </li>
                </ul>
                  <p className="fst-italic">

                    We honor our commitment and deliver beyond expectations. We are a leading IT firm that offers Professional
                    Mobile and Business Application development and Offshore Web designing and Marketing Solutions to clients.
                  </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-4.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>
          <div className="tab-pane" id="tab-3">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Wordpress Development</h3>
                <p>
                  Our eCommerce solutions allow large and small organizations to grow their business online.
                  We specialize in custom-tailored eCommerce website solutions at affordable prices.
                  We've provided solutions to a variety of industries including real estate, tours and travel, fashion and many more.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> WordPress Plugins & Theme Development.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> WordPress responsive web development and WordPress template design
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> we provide astonishing visual images with an impressive user and google friendly content website.
                  </li>
                </ul>
                <p className="fst-italic">
                 If you are looking for a 
                personal blog, Business website, E-commerce online shopping site, Unique design theme template, Theme plugin customization 
                maintenance for your existing website project or any other specific requirement kindly contact us.


                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-3.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-4">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Digital Marketing</h3>
                <p>
                  Social Media is an emerging platform to expand your target audience and reach.
                  Sassy Infotech handles virtual and image management; as we create a LinkedIn profile and maintain the brand page,
                  our experienced team gives you apt Social Media Audit and Reports with in-depth data analysis.
                  
                </p>

                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Landing pages optimization and Keyword Research and Strategy
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Advertising campaigns(Facebook, Instagram, Google Adwords)
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> We will help you find powerful ideas to understand and analyze your business’s 
                  needs and goals, so that we can choose the right strategy that will guarantee the best results</li>
                </ul>

                <p className="fst-italic">
                We also improve search engine ranking that augments brand recognition,
                  as we understand that a smart online marketing campaign will increase your product influence for sure.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-2.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-5">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>E-Commerce Websites</h3>
                <p>
                  Having a robust eCommerce website is the foundation of your online retail business, and we understand the importance of utilizing the latest technology to increase your profitability.
                  Our eCommerce solutions offer the best in standard features and functionality while remaining completely customizable.
                  Our team will discuss your custom functionality needs and tailor a solution that matches your online brand and eCommerce goals.
                </p>
                {/* <p className="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua.
          </p> */}
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ecommerce marketplace for business-to-business transactions
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> We offer platform-based services like woocommerce, Wix, Majento, and Shopify eCommerce development
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat
                    nulla pariatur.</li>
                </ul>
                <p className="fst-italic">
                Our expert team deliver safe and secure solutions using different types of online marketing strategies 
                to help build your business presence and increase your business brand.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-5.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-6">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Cloud Services</h3>
                <p>
                  We are the first company in surat providing cloud services to the clients, enables to develop sites without managing the resources.
                  our team of DevOps will manage your infrastructure and help you build your Applications.
                </p>
                <p className="fst-italic">
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Clouds like AWS, Google Cloud Platform, Microsoft Azure.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Monitoring and Smart Alert for any Updates and Errors.
                  </li>
                  <li><i className="bi bi-check-circle-fill"></i> Hosting Services on platforms like Heroku, Openshift, Netlify... </li>
                </ul>
                <p className="fst-italic">
                Our expert team will host and manage your Applications Securely. Will also help you create CI/CD for your projects so you can focus on development without any concern.
                Cloud Services will enables you to access your Storages and Services from any were and easy monitoring on your app.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-6.svg" alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>


  </>);
}
export default Services;