import React, { useState } from 'react'

function Contacts() {
  const intialValue = {
    name: '',
    email: '',
    message: '',
    subject: ''
  }
  const [data, setData] = useState(intialValue );
  // const setFields = (key, value) => {
  //   setData({ ...data, [key]: value });
  // }
  
  const handleInputChanges = e => {
    const {name , value} = e.target
    setData({
        ...data,
        [name] : value
    })
}
    const onSubmit = (e) => {
    e.preventDefault();
    console.log("data", data);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");




    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };

    fetch("https://api-mail.devbastion.in/send_mail", requestOptions)
      .then(response => response.json())
      .then((result) => { setData({}) })
      .catch(error => console.log('error', error));
    setData(intialValue);
  }


  return (<>
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-header">
          <h2>Contact Us</h2>
          <p>Need an expert? You’re welcome to leave your contact info & we’ll get in touch shortly.</p>
        </div>

      </div>

      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16345.145725100918!2d72.84166177612319!3d21.184873078783482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fc0e9a3e109%3A0xc7916a35c9c8f12d!2spolaris%20mall!5e0!3m2!1sen!2sin!4v1656932268335!5m2!1sen!2sin"
          frameBorder="0" title='location' allowFullScreen></iframe>
      </div>
      <div className="container">

        <div className="row gy-5 gx-lg-5">

          <div className="col-lg-4">

            <div className="info">
              <h3>Get in touch</h3>
              <p>For more information please contact us here.</p>


              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>team@unirktechnosys.com</p>
                </div>
              </div>              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>304, Pollaris Mall, Cenal Road, Surat, Gujarat, IN. 395006</p>
                </div>
              </div>
              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+91 9428505823</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            {/* <form action="localhost:3001/" method="post" role="form" className="php-email-form"> */}

            <form role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" value={data.name} className="form-control" id="name" placeholder="Your Name" required onChange={handleInputChanges} ></input>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" value={data.email} name="email" id="email" placeholder="Your Email" required onChange={handleInputChanges}></input>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" value={data.subject} name="subject" id="subject" placeholder="Subject" required onChange={handleInputChanges}></input>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" value={data.message} placeholder="Message" required onChange={handleInputChanges}></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit" onClick={onSubmit}>Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>

  </>);
}
export default Contacts;